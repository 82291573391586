<template>
  <div class="container">
    <div v-if="isPC" class="header-box">
      <div>
        <img v-if="detail.flag>-1" src="../../assets/images/purchase_icon_a.png" />
        <img v-else src="../../assets/images/purchase_icon.png" />
        <p>{{ $i18n.Order.Flow[0] }}</p>
        <p style="white-space: nowrap;">{{ detail.createTime }}</p>
      </div>
      <div :class="detail.flag>1?'arrowA':'arrow'"></div>
      <div>
        <img v-if="detail.flag>1" src="../../assets/images/payment_icon_a.png" />
        <img v-else src="../../assets/images/payment_icon.png" />
        <p>{{ $i18n.Order.Flow[1] }}</p>
        <p></p>
      </div>
      <div :class="detail.flag>2?'arrowA':'arrow'"></div>
      <div>
        <img v-if="detail.flag>2" src="../../assets/images/deliver_icon_a.png" />
        <img v-else src="../../assets/images/deliver_icon.png" />
        <p>{{ $i18n.Order.Flow[2] }}</p>
        <p></p>
      </div>
      <div :class="detail.flag>3?'arrowA':'arrow'"></div>
      <div>
        <img v-if="detail.flag>3" src="../../assets/images/collect_icon_a.png" />
        <img v-else src="../../assets/images/collect_icon.png" />
        <p>{{ $i18n.Order.Flow[3] }}</p>
        <p></p>
      </div>
      <div :class="detail.flag>4?'arrowA':'arrow'"></div>
      <div>
        <img v-if="detail.flag>4" src="../../assets/images/evaluate_icon_a.png" />
        <img v-else src="../../assets/images/evaluate_icon.png" />
        <p>{{ $i18n.Order.Flow[4] }}</p>
        <p></p>
      </div>
    </div>
    <div v-else class="step_mobile" >
      <div class="item">
        <img v-if="detail.flag>-1" src="../../assets/images/purchase_icon_a.png" />
        <img v-else src="../../assets/images/purchase_icon.png" />
        <p>{{ $i18n.Order.Flow[0] }}</p>
        <p style="white-space: nowrap;"></p>
      </div>
      <i class="el-icon-caret-right" :style="detail.flag>1?'color: #7cbc53': 'color: #ccc'" ></i>
      <div class="item">
        <img v-if="detail.flag>1" src="../../assets/images/payment_icon_a.png" />
        <img v-else src="../../assets/images/payment_icon.png" />
        <p>{{ $i18n.Order.Flow[1] }}</p>
        <p style="white-space: nowrap;"></p>
      </div>
      <i class="el-icon-caret-right" :style="detail.flag>2?'color: #7cbc53':'color: #ccc'" ></i>
      <div class="item">
        <img v-if="detail.flag>2" src="../../assets/images/deliver_icon_a.png" />
        <img v-else src="../../assets/images/deliver_icon.png" />
        <p>{{ $i18n.Order.Flow[2] }}</p>
        <p style="white-space: nowrap;"></p>
      </div>
      <i class="el-icon-caret-right" :style="detail.flag>3?'color: #7cbc53':'color: #ccc'" ></i>
      <div class="item">
        <img v-if="detail.flag>3" src="../../assets/images/collect_icon_a.png" />
        <img v-else src="../../assets/images/collect_icon.png" />
        <p>{{ $i18n.Order.Flow[3] }}</p>
        <p style="white-space: nowrap;"></p>
      </div>
      <i class="el-icon-caret-right" :style="detail.flag>4?'color: #7cbc53':'color: #ccc'" ></i>
      <div class="item">
        <img v-if="detail.flag>4" src="../../assets/images/evaluate_icon_a.png" />
        <img v-else src="../../assets/images/evaluate_icon.png" />
        <p>{{ $i18n.Order.Flow[4] }}</p>
        <p style="white-space: nowrap;"></p>
      </div>
    </div>

    <div :class="`${isPC?'header-box':''} order-msg`">
      <div>
        <div class="tit">
          {{ $i18n.Order.ConsigneeInformation }}
        </div>
        <table class="money-table ">
          <tr>
            <td>{{ $i18n.Order.Shipping_Address }}：</td>
            <td>{{ detail.receiveArea + ' ' + detail.receiveMan }}</td>
          </tr>
          <tr>
            <td>{{ $i18n.Order.Shipping_Address }}：</td>
            <td>{{ detail.receiveAddress }}</td>
          </tr>
          <tr>
            <td>{{ $i18n.Order.ContactInformation }}：</td>
            <td>{{ detail.receivePhone }}</td>
          </tr>
        </table>
      </div>
      <div class="fl" :style="`${isPC?'margin: 0 30px':'margin: 30px 0'}`">
        <div class="tit">
          {{ $i18n.Order.ModeDistribution }}
        </div>
        <table class="money-table ">
          <tr>
            <td>{{ $i18n.Order.ModeDistribution }}：</td>
            <td>{{ detail.expressCorpName || detail.flagText }}</td>
          </tr>
          <tr>
            <td>{{ $i18n.Order.Freight }}：</td>
            <td>€{{ detail.expressFee }}</td>
          </tr>
        </table>
      </div>
      <div class="fl">
        <div class="tit">
          {{ $i18n.Order.PaymentMethod }}
        </div>
        <table class="money-table ">
          <tr>
            <td>{{ $i18n.ShopCart.Subtotal }}：</td>
            <td>€{{ detail.totalAmount }}</td>
          </tr>
          <tr>
            <td>{{ $i18n.Order.AmountPayable }}：</td>
            <td>€{{ detail.totalAmount }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="cart" style="margin-top: 40px">
      <table>
        <tr>
          <th width="120">
            Mercancía
          </th>
          <th>Nombre</th>
          <th>
            <!-- Specification -->
          </th>
          <!-- <th>Reembolso</th> -->
          <th>Cantidad</th>
          <th>Precio unitario</th>
        </tr>
        <tr v-for="i in detail.goods">
          <td>
            <img class="goods-image" :src="i.cover" />
          </td>
          <td>
            {{ i.spuName | zxby }}
          </td>
          <td>
            <div v-show="i.specInfo !== 'default'">{{ i.specInfo }}</div>
          </td>
         <!-- <td>
            {{ i.rebate }}
          </td> -->
          <td>
            {{ i.num }}
          </td>
          <td>€{{ i.amount }}</td>
        </tr>
      </table>

      <div class="account-body" v-show="false">
        <div class="title">{{ $i18n.Order.BuyerMessage }}：</div>
        <textarea rows="3"></textarea>
        <div style="text-align:right;">
          <div class="order-btn__cont clear">
            <div class="btn-area fr" @click="addComment">
              {{ $i18n.Order.SubmitMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="row infos" v-show="detail.flag === 1">
        <div class="col-lg-7"></div>
        <div class="col-lg-5">
          <table class="money-table fr">
            <tr>
              <td>{{ $i18n.ShopCart.Subtotal }}：</td>
              <td>€{{ subtotal }}</td>
            </tr>
            <tr>
              <td>{{ $i18n.Order.Freight }}：</td>
              <td>€{{ detail.expressFee }}</td>
            </tr>
            <tr class="money">
              <td>
                <div>{{ $i18n.Order.TotalAmountText }}：</div>
              </td>
              <td>
                <div class="num">€{{ detail.totalAmount }}</div>
              </td>
            </tr>
          </table>

          <div class="order-btn__cont clear">
            <!-- <div class="btn-area fr" @click='handleCancelOrder'>
              {{loading2 ? $i18n.Order.PayLoading : $i18n.Order.PayText}}
            </div> -->

            <div class="btn-area fr" @click="pay">
              {{ loading2 ? $i18n.Order.PayLoading : $i18n.Order.PayText }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <form :action="formCont.form_action" method="post" accept-charset="utf-8" style="display:none" ref="formCont">
      <input type="text" name="Ds_MerchantParameters" v-model="formCont.Ds_MerchantParameters" />
      <input type="text" name="Ds_Signature" v-model="formCont.Ds_Signature" />
      <input type="text" name="Ds_SignatureVersion" v-model="formCont.Ds_SignatureVersion" />
    </form>
  </div>
</template>
<style lang="less">
.step_mobile{
  display: flex;
  justify-content: space-between;
  &>i{
    margin-top: 2vw;
    color: #7bbc52;
  }
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &>img{
      width: 6vw;
      margin-bottom: 1vw;
    }
    &>p{
      font-size: 3vw;
    }
  }
}
.cart {
  .btn-area {
    background: #333333;
    text-transform: uppercase;
    font-size: 14px;
    color: #ffffff;
    min-width: 135px;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    line-height: 38px;
    font-weight: 600;
    border: 0;
    transition: all 0.3s ease-in;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
    display: block;
    text-align: center;
    float: right;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      background-color: #fed100;
      color: #ffffff !important;
    }
  }

  .infos {
    td {
      padding: 5px;

      &:first-child {
        text-align: right;
      }
    }
  }

  .account-body {
    > div.title {
      margin-top: 10px;
      margin-bottom: 5px;
    }

    textarea {
      width: 100%;
      border-radius: 4px;
    }
  }

  .goods-image {
    max-width: 80px;
    max-height: 80px;
    padding: 2px;
    border: 1px solid #eee;
  }

  table {
    width: 100%;
  }

  th {
    padding: 10px 20px 10px 0;
  }
}

.header-box {
  display: flex;

  > div {
    flex: 1;
    text-align: center;
    flex-shrink: 0;
    img {
      display: inline-block;
    }

    p {
      font-size: 13px;
      margin-top: 10px;
    }

    &.arrow {
      background-image: url(../../assets/images/arrow_icon.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center 10px;
      margin: 0 30px;
    }
    &.arrowA{
      background-image: url(../../assets/images/arrow_icon_a.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center 10px;
      margin: 0 30px;
    }
  }
}
.order-msg {
    background: #f5f5f5;
    padding: 30px 10px;

    > div {
      .tit {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      text-align: left;
      font-size: 14px;

      td:first-child {
        vertical-align: top;
        white-space: nowrap;
      }

      td:nth-child(2) {
        padding-left: 10px; // font-weight: bold;
      }


      &:nth-child(3) {
        // margin: 0 20px;
        // flex-shrink: 1;
        // flex-grow: 0
      }
    }
  }
</style>
<script>
import { getOrderDetail, getPayConfig } from '@/api'
import judge from '../../judge.js'
export default {
  data() {
    return {
      isPC: false,
      orderId: 0,
      loading: 0, // 0加载中， 1，成功， -1，失败
      loading2: false, // 是否在支付中
      list: [],
      detail: {},
      addDetail: '',
      formCont: {},
      comment: {
        star: 0,
        content: ''
      }
    }
  },
  computed: {
    subtotal() {
      const { totalAmount = 0, expressFee = 0 } = this.detail
      const total = (totalAmount - expressFee).toFixed(2)
      return isNaN(total) ? 0 : total
    }
  },
  created() {
    this.isPC = judge.judge();
    this.$store.dispatch('setAppLoading', true)

    this.orderId = this.$route.params.id

    if (!this.orderId) {
      this.$store.dispatch('setAppLoading', false)
      return this.$router.replace('/')
    }
    this.getDetail()
  },
  methods: {
    // 添加评论？
    addComment() {
      if (!this.comment.star) {
        this.$toasted.error('Please select commodity rating first')
        return
      }
      if (!this.comment.content) {
        this.$toasted.error('Please enter the message first')
        return
      }
      let data = Object.assign({}, this.comment)
      data.order_id = this.$route.params.id

      return
    },
    pay() {
      if (this.loading2) return
      this.loading2 = true

      let params = {
        orderId: this.orderId,
        paymentMethod: 'redsys',
        apiVersion: '111'
      }
      getPayConfig(params).then(res => {
        if (res.data.status === 'fail') {
          this.loading2 = false
          this.$toasted.error(res.data.msg)
        } else {
          this.formCont = res.data.data.redsys
          this.$nextTick(() => {
            this.$refs.formCont.submit()
          })
        }
      })
    },
    goBank(suc) {},
    getDetail() {
      // getOrder

      getOrderDetail({ orderId: this.orderId }).then(res => {
        this.$store.dispatch('setAppLoading', false)

        if (res.data.status === 'SUCCESSS') {
          this.loading = 1
          this.detail = res.data.data
          this.detail.flag = parseInt(this.detail.flag);
        } else {
          this.loading = -1
        }
      })
    }
  }
}
</script>
